// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-archive-news-js": () => import("./../../../src/templates/archive-news.js" /* webpackChunkName: "component---src-templates-archive-news-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-pages-default-js": () => import("./../../../src/templates/pages/default.js" /* webpackChunkName: "component---src-templates-pages-default-js" */),
  "component---src-templates-single-keymarkets-js": () => import("./../../../src/templates/single-keymarkets.js" /* webpackChunkName: "component---src-templates-single-keymarkets-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */)
}

